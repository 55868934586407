<template>
  <article class="l-page l-page--login">
    <main class="l-page__main l-password-reset">
      <section class="l-password-inner">
        <header class="l-page__header">
          <h1 class="o-title">
            Reset Your Password
          </h1>
        </header>

        <b-overlay :show="showOverlay" rounded="sm">
          <b-form class="c-form__login" @submit.stop.prevent="onSubmit">
            <b-form-group id="example-input-group-1" label="Email:" label-for="username">
              <b-form-input
                id="username"
                v-model="$v.form.username.$model"
                name="username"
                :state="validateState('username')"
                aria-describedby="input-1-live-feedback"
              />

              <b-form-invalid-feedback
                id="input-1-live-feedback"
              >
                This is a required field.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-alert :show="requestFailed" variant="danger">
              User not found.
            </b-alert>

            <b-alert :show="requestSuccess" variant="success">
              Email for password reset is sent to you account.
            </b-alert>

            <b-button type="submit" class="c-button btn-lg" variant="primary">
              Reset Password
            </b-button>
          </b-form>
        </b-overlay>

        <hr>
        <b-button-toolbar justify>
          <b-link v-b-modal.modal-show-content-tc class="card-link">
            Terms and Conditions
          </b-link>
          <b-link v-b-modal.modal-show-content-pp href="#" class="card-link">
            Privacy Policy
          </b-link>
        </b-button-toolbar>
      </section>
      <ShowContent :id="'modal-show-content-tc'" :mdid="'rs911-terms-html'" :title="'Terms and Conditions'" />
      <ShowContent :id="'modal-show-content-pp'" :mdid="'rs911-privacy'" :title="'Privacy Policy'" />
    </main>
  </article>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { required, email } from 'vuelidate/lib/validators';
import GoogleLogin from 'vue-google-login';
import ShowContent from '@shared/components/ShowContent.vue';
Component.registerHooks(['validations']);

@Component({
  components: {
		GoogleLogin,
		ShowContent
  }
})
export default class RequestPasswordReset extends Vue {
  $v: any;
  requestFailed = false;
  requestSuccess = false;
  showOverlay = false;
  form = {
    username: null
  }

  validations() {
    return {
      form: {
        username: {
          required,
          email
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      username: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    this.requestFailed = false;
    this.requestSuccess = false;
    this.showOverlay = true;
    this.$store.dispatch('auth/requestPasswordReset', this.form.username)
      .then((res: any) =>  {
        this.requestSuccess = true;
        this.showOverlay = false;
        console.log(res);
      },
      () => {
        this.requestFailed = true;
        this.showOverlay = false;
      });
  }
}
</script>

<style scoped>
#appleid-signin {
  height: 50px !important;
}
</style>
